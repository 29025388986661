<template>
    <Layout>
        <div id="page">
            <div class="py-32pt card">
                <div class="container page__container">
                    <div class="progression-bar progression-bar--active-primary">

                        <div
                            class="progression-bar__item"
                            :class="{'progression-bar__item--complete progression-bar__item--complete': progress > 0,
                                    'progression-bar__item--active': progress === 0
                            }"
                        >
                            <span
                                class="progression-bar__item-content mx-auto"
                                @click="setProgress(0)"
                            >
                                <i
                                    class="material-icons progression-bar__item-icon"
                                    v-if="progress > 0"
                                >done
                                </i>
                                <i
                                    class="material-icons progression-bar__item-icon"
                                    v-else
                                ></i>
                                <span
                                    class="progression-bar__item-text h5 mb-0 text-uppercase"
                                >Informações Básicas
                                </span>
                            </span>
                        </div>

                        <div
                            class="progression-bar__item"
                            :class="{'progression-bar__item--complete': progress > 1,
                                    'progression-bar__item--active progression-bar__item--complete': progress === 1
                            }"
                        >
                            <span
                                class="progression-bar__item-content mx-auto"
                                @click="setProgress(1)"
                            >
                                <i
                                    class="material-icons progression-bar__item-icon"
                                    v-if="progress > 1"
                                >done
                                </i>
                                <i
                                    class="material-icons progression-bar__item-icon"
                                    v-else
                                ></i>
                                <span
                                    class="progression-bar__item-text h5 mb-0 text-uppercase"
                                >Detalhes
                                </span>
                            </span>
                        </div>

                        <div
                            class="progression-bar__item"
                            :class="{'progression-bar__item--complete': progress > 2,
                                    'progression-bar__item--active progression-bar__item--complete': progress === 2
                            }"
                        >
                            <span
                                class="progression-bar__item-content mx-auto"
                                @click="setProgress(2)"
                            >
                                <i
                                    class="material-icons progression-bar__item-icon"
                                    v-if="progress > 2"
                                >done
                                </i>
                                <i
                                    class="material-icons progression-bar__item-icon"
                                    v-else
                                ></i>
                                <span
                                    class="progression-bar__item-text h5 mb-0 text-uppercase"
                                >Landing Page
                                </span>
                            </span>
                        </div>

                        <div
                            class="progression-bar__item"
                            :class="{'progression-bar__item--complete': progress > 3,
                                    'progression-bar__item--active progression-bar__item--complete': progress === 3
                            }"
                        >
                            <span
                                class="progression-bar__item-content mx-auto"
                                @click="setProgress(3)"
                            >
                                <i
                                    class="material-icons progression-bar__item-icon"
                                    v-if="progress > 3"
                                >done
                                </i>
                                <i
                                    class="material-icons progression-bar__item-icon"
                                    v-else
                                ></i>
                                <span
                                    class="progression-bar__item-text h5 mb-0 text-uppercase"
                                >Mídia
                                </span>
                            </span>
                        </div>

                        <div
                            class="progression-bar__item"
                            :class="{'progression-bar__item--active progression-bar__item--complete': progress === 4}"
                        >
                            <span
                                class="progression-bar__item-content mx-auto"
                                @click="setProgress(4)"
                            >
                                <i class="material-icons progression-bar__item-icon"></i>
                                <span
                                    class="progression-bar__item-text h5 mb-0 text-uppercase"
                                >Módulos
                                </span>
                            </span>
                        </div>

                    </div>
                </div>
            </div>

            <div
                class="row my-3"
                v-if="progress < 3 || !addModule && type === 'list' && !preview"
            >
                <div class="col text-center">
                    <button
                        type="button"
                        class="btn btn-outline-danger mr-2"
                        v-if="progress > 0"
                        @click="back"
                    >
                        Voltar
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-success"
                        v-if="progress < 3"
                        @click="next"
                    >
                        Continuar
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        @click="save(true)"
                        v-if="progress === 3"
                    >
                        Salvar
                    </button>
                </div>
            </div>


            <div
                class="card mt-5"
                v-show="progress === 0"
            >
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-12">

                            <input
                                type="hidden"
                                v-model="formData.id"
                            >

                            <label class="form-label">Título da Mentoria
                                <span class="text-danger">*</span>
                                <i
                                    v-b-tooltip.hover.right="'Este conteúdo será exibido na landing page da mentoria.'"
                                    class="fa fa-exclamation-circle my-auto ml-2"
                                ></i>
                            </label>
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Título da Mentoria"
                                    v-model="formData.title"
                                    required
                                >

                            </div>

                            <div class="form-group forms mb-64pt">

                                <label class="form-label justify-content-between d-flex">
                                    <span>
                                        Resumo
                                        <span class="fs-10 text-muted">Máximo 1000 caracteres</span>
                                    </span>
                                </label>
                                <textarea
                                    ref="editor2"
                                    class="form-control"
                                    @input="checkLength"
                                    v-model="formData.resume"
                                    style="height: 150px"
                                    required
                                ></textarea>
                                <b-badge
                                    :variant="formData.resume.length <= 700 ? 'success' : (
                            formData.resume.length > 700 && formData.resume.length <= 980 ? 'warning' : 'danger'
                        )"
                                    class="text-white mx-auto contador bold fs-12"
                                >
                                    {{ formData.resume.length }}/1000
                                </b-badge>
                            </div>


                            <div class="form-group forms mb-64pt">
                                <div class="d-flex justify-content-between">
                                    <label class="form-label d-flex">
                                        <span>
                                            Descrição
                                        </span>
                                        <i
                                            v-b-tooltip.hover.right="'Este conteúdo será exibido na landing page da mentoria.'"
                                            class="fa fa-exclamation-circle my-auto ml-2"
                                        ></i>
                                    </label>
                                    <div class="d-flex">
                                        <button
                                            class="btn btn-outline-info btn-sm mb-1"
                                            id="buttonGenerate"
                                            type="button"
                                            @click.stop="generateDescription"
                                        >
                                            Gerar automaticamente
                                        </button>
                                        <i
                                            v-b-tooltip.hover.right="'Gerar a descrição da mentoria via inteligência artificial de acordo com o título e o resumo.'"
                                            class="fa fa-info-circle ml-2 my-auto"
                                        ></i>
                                    </div>
                                </div>
                                <textarea
                                    style="height: 150px"
                                    class="form-control"
                                    v-model="formData.description"
                                    required
                                ></textarea>
                            </div>

                            <div class="form-group forms mb-64pt">
                                <label class="form-label d-flex justify-content-between">
                                    <span>Termos de Uso da Mentoria</span>
                                    <i
                                        v-b-tooltip.hover.right="'Descreva aqui as orientações gerais da mentoria.'"
                                        class="fa fa-info-circle"
                                    ></i>
                                </label>
                                <textarea
                                    style="height: 150px"
                                    class="form-control"
                                    v-model="formData.policy"
                                    required
                                ></textarea>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

            <div
                class="card mt-5"
                v-show="progress === 1"
            >
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-4">

                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label class="form-label">Cor da Mentoria</label>
                                    <i
                                        v-b-tooltip.hover.right="'A cor que você escolher será usada como destaque principal nas mentorias, aplicando-se também aos textos e outros elementos visuais para garantir uma identidade harmoniosa e personalizada.'"
                                        class="fa fa-info-circle pointer"
                                    ></i>
                                </div>
                                <input
                                    type="color"
                                    class="form-control"
                                    placeholder="Cor da Mentoria"
                                    v-model="formData.color"
                                    id="mentoring_color"
                                >
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="form-group">
                                <label class="form-label">Formulário de Aplicação</label>
                                <select
                                    @change="setTextApplicationForm()"
                                    name="category"
                                    class="form-control"
                                    v-model="formData.application_form"
                                >
                                    <option value="">Selecionar</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>
                        </div>

                        <div
                            class="col-md-4"
                            v-if="formData.application_form == '1'"
                        >

                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label class="form-label">Formulário</label>
                                    <a
                                        href="/formularios/cadastrar"
                                        target="_blank"
                                        title="Novo Formulário"
                                    >
                                        <i class="fa fa-plus-circle"></i>
                                    </a>
                                </div>
                                <select
                                    name="category"
                                    class="form-control"
                                    v-model="formData.application_form_id"
                                >
                                    <option value="">Selecionar</option>
                                    <option
                                        v-for="form in forms"
                                        :value="form.id"
                                        :key="`select-form-application-${form.id}`"
                                    >
                                        {{ form.title }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div
                            class="col-md-12"
                            v-if="formData.application_form == '1'"
                        >
                            <div class="form-group">
                                <label>Texto introdutório ao formulário de aplicação</label>
                                <textarea
                                    class="form-control"
                                    v-model="formData.application_form_text"
                                    rows="10"
                                ></textarea>
                            </div>
                        </div>

                        <div class="col-md-4">

                            <div class="form-group">
                                <label class="form-label">Tipo</label>
                                <select
                                    name="category"
                                    class="form-control"
                                    v-model="formData.type_id"
                                >
                                    <option value="">Selecionar</option>
                                    <option value="1">Individual</option>
                                    <option value="2">Em grupo</option>
                                </select>
                            </div>
                        </div>
                        <div
                            class="col-md-4"
                            v-if="formData.type_id == '2'"
                        >
                            <div class="form-group">
                                <label class="form-label">Vagas</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Vagas"
                                    v-model="formData.vacancies"
                                >
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label">Preço</label>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group form-inline">
                                            <span class="input-group-prepend">
                                                <span
                                                    class="input-group-text"
                                                >R$
                                                </span>
                                            </span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="price"
                                                required
                                                @keyup="formatMoneyDecimal('price', false)"
                                                v-model="formData.price"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label">Preço Promocional</label>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group form-inline">
                                            <span class="input-group-prepend">
                                                <span
                                                    class="input-group-text"
                                                >R$
                                                </span>
                                            </span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="promotional_price"
                                                @keyup="formatMoneyDecimal('promotional_price', false)"
                                                v-model="formData.promotional_price"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label class="form-label">Palavras Chave</label>
                                    <div>
                                        <button
                                            class="btn btn-outline-info btn-sm mb-1"
                                            id="buttonGenerate"
                                            type="button"
                                            @click.stop="generateTags"
                                        >
                                            Gerar automaticamente
                                        </button>
                                        <i
                                            v-b-tooltip.hover.right="'Gerar as Palavras chave de acordo com a Descrição da Mentoria'"
                                            class="fa fa-info-circle ml-1 my-auto"
                                        ></i>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    required
                                    @keydown.enter="addTag()"
                                    placeholder="Precione Enter para confirmar"
                                    v-model="tag"
                                >
                                <span class="fs-10 text-muted ">Máximo 5</span>

                                <div
                                    class="tag mt-2"
                                    v-for="(tag, index) in tags"
                                    :key="index"
                                >
                                    {{ tag.tag }}
                                    <span
                                        class="remove-tag"
                                        @click="removeTag(index)"
                                    >×
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div
                class="card mt-5"
                v-show="progress === 2"
            >
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="mb-4">Configurações da Landing Page</h4>
                            
                            <div class="form-group">
                                <label class="form-label">Seção Benefícios</label>
                                <div class="mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="formData.benefits_title"
                                        placeholder="Título da seção de benefícios"
                                    >
                                </div>
                                <div class="d-flex justify-content-end mb-3">
                                    <button
                                        class="btn btn-outline-info btn-sm"
                                        type="button"
                                        @click.stop="generateBenefits"
                                    >
                                        Gerar benefícios automaticamente
                                    </button>
                                    <i
                                        v-b-tooltip.hover.right="'Gerar benefícios da mentoria via inteligência artificial de acordo com o título e a descrição.'"
                                        class="fa fa-info-circle ml-2 my-auto"
                                    ></i>
                                </div>
                                <div v-for="(benefit, index) in formData.benefits" :key="index" class="card mb-3">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Título do Benefício</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="benefit.title"
                                                placeholder="Digite o título do benefício"
                                            >
                                        </div>
                                        <div class="form-group">
                                            <label>Descrição do Benefício</label>
                                            <textarea
                                                class="form-control"
                                                v-model="benefit.description"
                                                rows="3"
                                                placeholder="Descreva o benefício"
                                            ></textarea>
                                        </div>
                                        <button 
                                            class="btn btn-outline-danger"
                                            @click="removeBenefit(index)"
                                        >
                                            Remover Benefício
                                        </button>
                                    </div>
                                </div>
                                <button 
                                    class="btn btn-outline-primary"
                                    @click="addBenefit"
                                >
                                    Adicionar Benefício
                                </button>
                            </div>

                            <hr class="my-5 border-top-2 border-primary">

                            <div class="form-group">
                                <label class="form-label">Seção Depoimentos</label>
                                <div class="mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="formData.testimonials_title"
                                        placeholder="Título da seção de depoimentos"
                                    >
                                </div>
                                <div class="d-flex justify-content-end mb-3">
                                    <button
                                        class="btn btn-outline-info btn-sm"
                                        type="button"
                                        @click.stop="generateTestimonials"
                                    >
                                        Gerar depoimentos automaticamente
                                    </button>
                                    <i
                                        v-b-tooltip.hover.right="'Gerar depoimentos da mentoria via inteligência artificial de acordo com o título e a descrição.'"
                                        class="fa fa-info-circle ml-2 my-auto"
                                    ></i>
                                </div>
                                <div v-for="(testimonial, index) in formData.testimonials" :key="index" class="card mb-3">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Nome do Aluno</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="testimonial.name"
                                                placeholder="Nome do aluno"
                                            >
                                        </div>
                                        <div class="form-group">
                                            <label>Depoimento</label>
                                            <textarea
                                                class="form-control"
                                                v-model="testimonial.text"
                                                rows="3"
                                                placeholder="Texto do depoimento"
                                            ></textarea>
                                        </div>
                                        <div class="form-group">
                                            <label>Foto do Aluno</label>
                                            <input
                                                type="file"
                                                class="form-control"
                                                @change="handleTestimonialImageChange($event, index)"
                                                accept="image/*"
                                            >
                                        </div>
                                        <button 
                                            class="btn btn-outline-danger"
                                            @click="removeTestimonial(index)"
                                        >
                                            Remover Depoimento
                                        </button>
                                    </div>
                                </div>
                                
                                <button 
                                    class="btn btn-outline-primary"
                                    @click="addTestimonial"
                                >
                                    Adicionar Depoimento
                                </button>
                            </div>

                            <hr class="my-5 border-top-2 border-primary">

                            <div class="form-group">
                                <label class="form-label">Seção FAQ</label>
                                <div class="mb-3">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="formData.faq_title"
                                        placeholder="Título da seção FAQ"
                                    >
                                </div>
                                <div class="d-flex justify-content-end mb-3">
                                    <button
                                        class="btn btn-outline-info btn-sm"
                                        type="button"
                                        @click.stop="generateFaqs"
                                    >
                                        Gerar FAQs automaticamente
                                    </button>
                                    <i
                                        v-b-tooltip.hover.right="'Gerar perguntas frequentes da mentoria via inteligência artificial de acordo com o título e a descrição.'"
                                        class="fa fa-info-circle ml-2 my-auto"
                                    ></i>
                                </div>
                                <div v-for="(faq, index) in formData.faqs" :key="index" class="card mb-3">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Pergunta</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="faq.question"
                                                placeholder="Digite a pergunta"
                                            >
                                        </div>
                                        <div class="form-group">
                                            <label>Resposta</label>
                                            <textarea
                                                class="form-control"
                                                v-model="faq.answer"
                                                rows="3"
                                                placeholder="Digite a resposta"
                                            ></textarea>
                                        </div>
                                        <button 
                                            class="btn btn-outline-danger"
                                            @click="removeFaq(index)"
                                        >
                                            Remover Pergunta
                                        </button>
                                    </div>
                                </div>
                                <button 
                                    class="btn btn-outline-primary"
                                    @click="addFaq"
                                >
                                    Adicionar Pergunta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="card mt-5"
                v-show="progress === 3"
            >
                <div class="card-body">

                    <div class="row">

                        <div class="col-md-6 my-auto mb-3">
                            <label class="form-label mt-2">Logo da mentoria</label>
                            <input
                                type="file"
                                id="logo"
                                class="form-control mb-3"
                                @change="handleImageChangeLogo"
                                required
                                accept=".jpg, .jpeg, .png, .webp"
                            >
                            <div class="alert alert-warning">
                                Dimensões indicadas: 600 x 600px
                            </div>
                            <div
                                class="alert alert-danger"
                                v-if="show_alert_logo"
                            >
                                As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                                distorções ou perda de qualidade ao ser exibida.
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <b-skeleton-img
                                v-if="loaded.indexOf('logo') < 0 && formData.logoUrl"
                                style="aspect-ratio: 320 / 480;"
                            />
                            <img
                                v-show="loaded.indexOf('logo') > -1 && formData.logoUrl"
                                :src="formData.logoUrl"
                                id="mentoring_img"
                                class="mentor-imagem"
                                alt="nova_logo_mentoria"
                                @load="loaded.push('logo')"
                            >
                            <Cropper
                                v-if="formData.logo && !formData.logoUrl"
                                :img="formData.logo"
                                :proportion="1"
                                @set-img="setImgLogo"
                                @reset-logo="resetLogo"
                            />

                            <img
                                v-else-if="!formData.logo && !formData.logoUrl"
                                class="img-fluid"
                                src="../../../assets/images/logo-default.png"
                                id="mentoring_img"
                                alt="logo_mentoria"
                            >
                        </div>

                        <div class="col-md-6 my-auto mb-lg-5 mt-lg-5 mb-3">
                            <label class="form-label mt-2">Banner Desktop da Landing Page</label>
                            <input
                                type="file"
                                id="desktop"
                                class="form-control mb-3"
                                @change="handleImageChangeDesktop"
                                required
                                accept=".jpg, .jpeg, .png, .webp"
                            >
                            <div class="alert alert-warning">
                                Dimensões indicadas: 2000 x 590
                            </div>
                            <div
                                class="alert alert-danger"
                                v-if="show_alert_desktop"
                            >
                                As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                                distorções ou perda de qualidade ao ser exibida.
                            </div>
                        </div>
                        <div class="col-md-6 mb-lg-5 mt-lg-5 mb-3">
                            <img
                                v-if="formData.desktopUrl"
                                :src="formData.desktopUrl"
                                id="mentoring_img"
                                class="mentor-imagem"
                                alt="nova_desktop_mentoria"
                            >
                            <Cropper
                                v-else-if="formData.desktop && !formData.desktopUrl"
                                :img="formData.desktop"
                                :proportion="3.39"
                                @set-img="setImgDesktop"
                                @reset-logo="resetDesktop"
                            />
                            <img
                                v-else
                                class="img-fluid"
                                src="../../../assets/images/desktop.png"
                                id="mentoring_img"
                                alt="desktop_mentoria"
                            >
                        </div>

                        <div class="col-md-6 my-auto mb-3">
                            <label class="form-label mt-2">Banner Mobile da Landing Page</label>
                            <input
                                type="file"
                                id="mobile"
                                class="form-control mb-3"
                                @change="handleImageChangeMobile"
                                required
                                accept=".jpg, .jpeg, .png, .webp"
                            >
                            <div class="alert alert-warning">
                                Dimensões indicadas: 400 x 400
                            </div>
                            <div
                                class="alert alert-danger"
                                v-if="show_alert_mobile"
                            >
                                As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                                distorções ou perda de qualidade ao ser exibida.
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <img
                                v-if="formData.mobileUrl"
                                :src="formData.mobileUrl"
                                id="mentoring_img"
                                class="mentor-imagem"
                                alt="nova_mobile_mentoria"
                            >
                            <Cropper
                                v-else-if="formData.mobile && !formData.mobileUrl"
                                :img="formData.mobile"
                                :proportion="1"
                                @set-img="setImgMobile"
                                @reset-logo="resetMobile"
                            />
                            <img
                                v-else
                                class="img-fluid"
                                src="../../../assets/images/desktop.png"
                                id="mentoring_img"
                                alt="mobile_mentoria"
                            >
                        </div>

                        <div class="col-md-6 my-auto">
                            <label class="form-label mt-2 d-flex justify-content-between">
                                <span>Banner Padrão dos Conteúdos</span>
                                <i
                                    v-b-tooltip.hover.right="'Esta imagem será exibida em conteúdos que não tiverem nenhuma capa cadastrada.'"
                                    class="fa fa-info-circle"
                                ></i>
                            </label>
                            <input
                                type="file"
                                id="content"
                                class="form-control mb-3"
                                @change="handleImageChangeContent"
                                required
                                accept=".jpg, .jpeg, .png, .webp"
                            >
                            <div class="alert alert-warning">
                                Dimensões indicadas: 142 x 80
                            </div>
                            <div
                                class="alert alert-danger"
                                v-if="show_alert_content"
                            >
                                As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                                distorções ou perda de qualidade ao ser exibida.
                            </div>
                        </div>
                        <div class="col-md-6 mb-lg-5 mt-lg-5 mb-3">
                            <img
                                v-if="formData.contentUrl"
                                :src="formData.contentUrl"
                                id="mentoring_img"
                                class="mentor-imagem"
                                alt="nova_content_mentoria"
                            >
                            <Cropper
                                v-else-if="formData.default_content && !formData.contentUrl"
                                :img="formData.default_content"
                                :proportion="1.78"

                                @set-img="setImgContent"
                                @reset-logo="resetContent"
                            />
                            <img
                                v-else
                                class="img-fluid"
                                src="../../../assets/images/content.png"
                                id="content_img"
                                alt="content_mentoria"
                            >
                        </div>

                        <div class="col-md-6 mb-3 my-auto">
                            <label class="form-label mt-2 d-flex justify-content-between">
                                <span>
                                    Vídeo de Apresentação
                                    <i
                                        v-b-tooltip.hover.right="'Este vídeo será exibido na landing page da mentoria.'"
                                        class="fa fa-exclamation-circle my-auto ml-2"
                                    ></i>
                                </span>
                                <i
                                    v-b-tooltip.hover.right="'Link deve ser do YouTube.'"
                                    class="fa fa-info-circle"
                                ></i>
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="formData.video"
                                required
                                placeholder="Insira uma URL de vídeo"
                            >
                        </div>
                        <div
                            class="col-md-6 mb-3"
                            style="min-height: 250px;"
                        >
                            <div class="embed-responsive embed-responsive-16by9 my-auto">
                                <iframe
                                    class="embed-responsive-item"
                                    :src="getUrlVideo(formData.video)"
                                    allowfullscreen=""
                                ></iframe>
                            </div>
                        </div>


                    </div>

                </div>
            </div>


            <div
                class="row mt-5"
                v-show="progress === 3 || progress === 4"
            >
                <div class="col-md-12">

                    <div
                        class="d-flex justify-content-between"
                        v-if="type==='list' && (!preview && !addModule)"
                    >
                        <div>
                            <label class="form-label">Módulos</label>
                            <i
                                style="color:black"
                                v-b-tooltip.hover.right="'Aqui é criado o plano de conteúdo da mentoria, informe os módulos e os itens(conteúdos). Podendo ser vídeo, arquivo, formulário, atividade, agendamento ou leitura.'"
                                class="fa fa-info-circle pointer"
                            ></i>
                        </div>
                        <i
                            class="fa fa-sort pointer"
                            title="Reordenar"
                            v-if="type === 'list' && !preview"
                            @click="type='drag'"
                        ></i>
                    </div>


                    <ListModules
                        v-show="type==='list' && !preview && !addModule"
                        @edit-module="previewModule"
                    />

                    <Drag
                        v-if="type==='drag'"
                        @set-type="type='list'"
                    />

                    <Preview
                        :index-module="index_module"
                        @cancel="preview=false"
                        v-if="preview && type === 'list'"
                    />

                    <AddModule
                        @cancel="addModule=false"
                        @moduleAdded="moduleAdded()"
                        :color="formData.color"
                        :index-module="index_module"
                        v-if="addModule"
                    />

                    <button
                        v-if="!addModule && type === 'list' && !preview"
                        type="button"
                        class="btn btn-outline-primary mb-24pt mb-sm-0 mt-4"
                        @click="newModule"
                    >
                        Adicionar Módulo
                    </button>
                </div>
            </div>
        </div>

        <div
            class="row my-3"
            v-if="progress < 3 || !addModule && type === 'list' && !preview"
        >
            <div class="col text-center">
                <button
                    type="button"
                    class="btn btn-outline-danger mr-2"
                    v-if="progress > 0"
                    @click="back"
                >
                    Voltar
                </button>
                <button
                    type="button"
                    class="btn btn-outline-success"
                    v-if="progress < 3"
                    @click="next"
                >
                    Continuar
                </button>
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    id="save"
                    @click="save(true)"
                    v-if="progress === 3"
                >
                    Salvar
                </button>
            </div>
        </div>

    </Layout>

</template>

<!--eslint-disable no-unused-vars-->
<script>
import Layout from '@/components/layout/main.vue';
import AddModule from "@/views/mentoring/register/add-module.vue";
import ListModules from "@/views/mentoring/register/list-modules.vue";
import Drag from "@/views/mentoring/register/drag.vue";
import Preview from './preview.vue';
import {endLoading, startLoading} from "@/components/composables/spinners";
import {toFormData, ValidateForm} from "@/components/composables/cruds";
import http from "@/http"
import {getUrlVideo} from "@/components/composables/functions";
import Cropper from "@/components/base/cropper.vue";
import {messageSwal} from "@/components/composables/messages";
import _ from 'lodash';
import {handleImg} from "@/components/composables/img";

export default {
    components: {
        Layout,
        Cropper,
        AddModule,
        ListModules,
        Drag,
        Preview,
    },

    data() {
        return {
            type: 'list',
            drag: false,
            show: false,
            preview: false,
            loaded: [],
            progress: 0,
            img: '',
            imgCropped: '',
            addModule: false,
            index_module: null,
            show_alert_logo: false,
            show_alert_desktop: false,
            show_alert_mobile: false,
            show_alert_content: false,
            copy: {
                id: 0,
                title: '',
                resume: '',
                description: '',
                policy: '',
                sections: [],
                logo: '',
                logoUrl: null,
                desktop: '',
                desktopUrl: null,
                mobile: '',
                mobileUrl: null,
                default_content: '',
                contentUrl: null,
                video: '',
                category_id: '',
                type_id: '',
                link: '',
                price: '',
                status: 0,
                photo: '',
                vacancies: '',
                application_form_id: '',
                application_form: '',
                application_form_text: '',
                color: '',
                promotional_price: '',
            },
            formData: {
                id: 0,
                title: '',
                resume: '',
                description: '',
                policy: '',
                sections: [],
                logo: '',
                logoUrl: null,
                desktop: '',
                desktopUrl: null,
                mobile: '',
                mobileUrl: null,
                default_content: '',
                contentUrl: null,
                video: '',
                category_id: '',
                type_id: '',
                link: '',
                price: '',
                status: 0,
                photo: '',
                vacancies: '',
                application_form_id: '',
                application_form: '',
                application_form_text: '',
                color: '#000000',
                benefits_title: '',
                benefits: [],
                testimonials_title: '',
                testimonials: [],
                faq_title: '',
                faqs: [],
                promotional_price: '',
            },
            forms: [],
            tag: '',
            tags: [],
        }
    },
    methods: {

        moduleAdded() {
            this.addModule = false

            this.save()
        },
        async setProgress(value) {
            if (this.formData.title === '') {
                this.$notifyError('O campo Título é obrigatório!');
                return;
            }

            await this.save();

            this.progress = value;
        },

        async next() {
            if (this.formData.title === '') {
                this.$notifyError('O campo Título é obrigatório!');
                return;
            }

            await this.save();

            ++this.progress;
        },

        async back() {
            if (this.formData.title === '') {
                this.$notifyError('O campo Título é obrigatório!');
                return;
            }

            await this.save();

            --this.progress;
        },

        previewModule(indexModule) {
            this.index_module = indexModule;
            this.preview = true;
        },

        editModule(indexModule) {
            this.index_module = indexModule;
            this.addModule = true;
        },

        newModule() {
            this.index_module = null;
            this.addModule = true;
        },

        getForms() {
            http.get('forms/?paginated=false&orderByAsc=title')
                .then(response => {
                    this.forms = response.data.data
                    this.$emit('update-forms', response.data.data);
                })
        },

        formatMoneyDecimal(id, money = true) {
            const value = document.getElementById(id).value;
            // Remove todos os caracteres que não sejam dígitos
            if (value === '') {
                if (id === 'price') {
                    this.formData.price = '';
                } else if (id === 'promotional_price') {
                    this.formData.promotional_price = '';
                }
                return;
            }

            var cleanValue = value.replace(/\D/g, '');

            // Divide o valor em parte inteira e decimal
            var integerPart = cleanValue.substring(0, cleanValue.length - 2);
            const verify = integerPart.charAt(0);
            if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
            if (integerPart === '' || integerPart == 0) integerPart = '0';
            var decimalPart = cleanValue.substring(cleanValue.length - 2);
            if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

            // Adiciona separador de milhar
            integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

            let format = '';
            if (integerPart === '0' && decimalPart === '00') {
                format = '';
            } else if (money) {
                // Formata o valor como moeda (adiciona símbolo de moeda e sinal de negativo)
                format = 'R$ ' + integerPart + ',' + decimalPart;
            } else {
                // Formata o valor em decimal
                format = integerPart + ',' + decimalPart;
            }

            if (id === 'price') {
                this.formData.price = format;
            } else if (id === 'promotional_price') {
                this.formData.promotional_price = format;
            }
        },

        async goBack() {
            const deep = {
                ...this.formData,
                sections: this.$store.state.settingsMentoring.sections,
                tags: this.tags
            }
            if (_.isEqual(deep, this.copy)) {
                this.$router.go(-1);
            } else {
                const result = await messageSwal(
                    "Informações não salvas",
                    "Você realizou alteração a sua mentoria e não as salvou, tem certeza que deseja continuar?",
                    "warning"
                )

                if (result) {
                    this.$router.go(-1);
                }
            }
        },

        getUrlVideo,

        addTag() {
            if (this.tags.length > 4) {
                this.$notifyError('Máximo cinco palavras chave');
            } else {
                this.tags.push({
                    id: new Date().getTime() + '_tag',
                    tag: this.tag
                });
                this.tag = '';
            }
        },

        removeTag(index) {
            this.tags.splice(index, 1);
        },

        async handleImageChangeLogo(event) {
            try {
                const {url, img, alert} = await handleImg(event, 600, 600);
                this.formData.logo = img;
                this.formData.logoUrl = url;
                this.show_alert_logo = alert;
            } catch (error) {
                console.error("Erro ao carregar a imagem:", error);
            }
        },

        setImgLogo(blob) {
            this.formData.logoUrl = URL.createObjectURL(blob);
            this.formData.logo = new File([blob], "cropped-image.png", {type: 'image/png'});
        },

        resetLogo() {
            document.getElementById('logo').value = '';
            this.formData.logo = null;
            this.formData.logoUrl = null;
            this.show_alert_logo = false;
        },

        async handleImageChangeDesktop(event) {
            try {
                const {url, img, alert} = await handleImg(event, 600, 600);
                this.formData.desktop = img;
                this.formData.desktopUrl = url;
                this.show_alert_desktop = alert;

            } catch (error) {
                console.error("Erro ao carregar a imagem:", error);
            }
        },

        setImgDesktop(blob) {
            this.formData.desktopUrl = URL.createObjectURL(blob);
            this.formData.desktop = new File([blob], "cropped-image.png", {type: 'image/png'});
        },

        resetDesktop() {
            document.getElementById('desktop').value = '';
            this.formData.desktop = null;
            this.formData.desktopUrl = null;
            this.show_alert_desktop = false;
        },

        async handleImageChangeMobile(event) {
            try {
                const {url, img, alert} = await handleImg(event, 600, 600);
                this.formData.mobile = img;
                this.formData.mobileUrl = url;
                this.show_alert_mobile = alert;

            } catch (error) {
                console.error("Erro ao carregar a imagem:", error);
            }
        },

        setImgMobile(blob) {
            this.formData.mobileUrl = URL.createObjectURL(blob);
            this.formData.mobile = new File([blob], "cropped-image.png", {type: 'image/png'});
        },

        resetMobile() {
            document.getElementById('mobile').value = '';
            this.formData.mobile = null;
            this.formData.mobileUrl = null;
            this.show_alert_mobile = false;
        },

        async handleImageChangeContent(event) {
            try {
                const {url, img, alert} = await handleImg(event, 600, 600);
                this.formData.default_content = img;
                this.formData.contentUrl = url;
                this.show_alert_content = alert;

            } catch (error) {
                console.error("Erro ao carregar a imagem:", error);
            }
        },

        setImgContent(blob) {
            this.formData.contentUrl = URL.createObjectURL(blob);
            this.formData.default_content = new File([blob], "cropped-image.png", {type: 'image/png'});
        },

        resetContent() {
            document.getElementById('content').value = '';
            this.formData.default_content = null;
            this.formData.contentUrl = null;
            this.show_alert_content = false;
        },

        checkLength() {
            const length = this.formData.resume.length;
            if (length > 1000) {
                this.formData.resume = this.formData.resume.substr(0, 1000);
            }
        },

        inactive() {
            startLoading('page', 'buttonGenerate');
            this.$store.commit('api/setSpinner', true);
            http.get('mentorings/change-status/' + this.formData.id)
                .then(response => {
                    this.$notifySuccess(response.data);
                    this.formData.status = 0;
                })
                .catch(errors => {
                    console.log('Inactive Mentoring: ', errors.response.data.message);
                    this.$notifyError(errors);
                })
                .finally(() => {
                    endLoading('page', 'buttonGenerate');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        generateDescription() {
            const content = this.formData.title + this.formData.resume;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-resume', {content})
                .then(response => {
                    this.formData.description = response.data[0];
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

        generateTags() {
            const content = this.formData.description;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-tags', {content})
                .then(response => {
                    response.data.forEach(el => {
                        this.tags.push({
                            tag: el
                        })
                    })
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

        async save(redirect = false) {
            let result = true;


            if (result) {

                startLoading('page', 'save');
                this.$store.commit('api/setSpinner', true);
                let required = {
                    title: 'Título',
                };

                // inserir campos obrigatórios no array
                // if (active) {
                //     required = {
                //         title: 'Título',
                //         resume: 'Resumo',
                //         description: 'Descrição',
                //         policy: 'Políticas da Mentoria',
                //         logo: 'Logo',
                //         video: 'Vídeo',
                //         type_id: 'Tipo',
                //         price: 'Preço'
                //     };
                //
                //     if (this.formData.photo && this.formData.photo.length > 0) {
                //         delete required.logo;
                //     }
                //
                // } else {
                //     required = {
                //         title: 'Título',
                //     };
                // }

                // validando os campos do formulário
                if (!ValidateForm(this.$root, this.formData, required)) {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                    return;
                }

                // passa o sections da store para o formData
                this.formData.sections = this.$store.state.mentoring.modules

                // validando os conteúdos
                const hasEmptyFields = this.formData.sections.some(section => {
                    return section.contents.some(content => {
                        if (
                            content.type === '' ||
                            content.subject === '' ||
                            (content.type == 1 && (content.video === null || content.video === '')) ||
                            (content.type == 2 && ((content.doc === null || content.doc === '') && content.url === '')) ||
                            (content.type == 3 && (content.form_id === null || content.form_id === '')) ||
                            (content.type == 4 && (
                                content.activity.type === null || content.activity.type === '' ||
                                content.activity.description === null || content.activity.description === '' ||
                                ((content.activity.type == 2 || content.activity.type == 3) && content.activity.alternatives.length === 0)
                            )) ||
                            (content.type == 6 && (content.description === null || content.description === ''))
                        ) {
                            this.$notifyError('Preencha todos os campos dos conteudos!');
                            endLoading('page', 'save');
                            this.$store.commit('api/setSpinner', false);
                            return true;
                        }
                        return false;
                    });
                });

                if (hasEmptyFields) {
                    return;
                }

                // inserindo os campos do formulário em um FormData
                const data = toFormData(this.formData);

                // salvando os arquivos em um array de arquivos e salvando a chave do array dentro da Módulo
                this.formData.sections.forEach((element, index) => {

                    if (element.img) data.append(`section_img[${element.id}]`, element.img)

                    element.contents.forEach((content, i) => {
                        if (content.doc) {
                            data.append(`docs[${index}${i}]`, content.doc);
                            this.formData.sections[index].contents[i].docKey = `${index}${i}`;
                        }

                        if (content.banner) data.append(`content[${content.id}]`, content.banner);
                    })
                })

                // convertendo as seções em json para enviar em um array
                data.append('sections', JSON.stringify(this.formData.sections));

                // salvando as tags em array
                if (this.tags.length > 0) {
                    this.tags.forEach((tag, indexTag) => {
                            data.append(`tags[${indexTag}][id]`, tag.id);
                            data.append(`tags[${indexTag}][tag]`, tag.tag);
                    })
                }

                // Salvando dados da landing page
                data.append('benefits_title', this.formData.benefits_title);
                data.append('testimonials_title', this.formData.testimonials_title);
                data.append('faq_title', this.formData.faq_title);
                
                // Salvando benefícios
                if (this.formData.benefits.length > 0) {
                    this.formData.benefits.forEach((benefit, index) => {
                        data.append(`benefits[${index}][title]`, benefit.title);
                        data.append(`benefits[${index}][description]`, benefit.description);
                    });
                }

                // Salvando depoimentos
                if (this.formData.testimonials.length > 0) {
                    this.formData.testimonials.forEach((testimonial, index) => {
                        data.append(`testimonials[${index}][name]`, testimonial.name);
                        data.append(`testimonials[${index}][text]`, testimonial.text);
                        if (testimonial.photo) {
                            data.append(`testimonials[${index}][photo]`, testimonial.photo);
                        }
                    });
                }

                // Salvando FAQs
                if (this.formData.faqs.length > 0) {
                    this.formData.faqs.forEach((faq, index) => {
                        data.append(`faqs[${index}][question]`, faq.question);
                        data.append(`faqs[${index}][answer]`, faq.answer);
                    });
                }

                // se for ativar a mentoria, inserir status 1
                // if (active) data.append('status', 1);

                let url = 'mentorings';

                if (this.formData.id > 0) {
                    url += `/${this.formData.id}`;
                    data.append('_method', 'PUT');
                }

                http.post(url, data)
                    .then(response => {

                        if (response.data && response.data.message) {
                            this.$notifySuccess(response.data.message);
                        }

                        if (response.data && response.data.data) {
                            this.formData.id = response.data.data;
                        }

                        if (this.formData.promotional_price) this.formData.promotional_price = this.formData.promotional_price.replace('.', ',')

                        if(redirect){

                            if(this.$route.params.id){
                                setTimeout(() => {
                                    this.$router.push('/mentorias');
                                }, 200)
                            } else {
                                setTimeout(() => {
                                    this.$router.push('/home');
                                }, 200)
                            }
                        }

                    })
                    .catch(e => {
                        console.error('Mentoring Register: ', e.response.data);
                        this.$notifyError(e)
                    })
                    .finally(() => {
                        endLoading('page', 'save');
                        this.$store.commit('api/setSpinner', false);
                    })
            }
        },

        getData(id) {
            startLoading('page', 'save');
            this.$store.commit('api/setSpinner', true);
            http.get('mentorings/' + id, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
                .then(response => {
                    const keys = Object.keys(response.data);

                    keys.forEach(key => {
                        this.formData[key] = response.data[key] ?? '';
                    })

                    this.formData.tags = response.data.tags;
                    this.formData.benefits = response.data.benefits || [];
                    this.formData.testimonials = response.data.testimonials || [];
                    this.formData.faqs = response.data.faqs || [];

                    this.tags = response.data.tags

                    const sections = response.data.sections;


                    this.$store.commit('mentoring/setModules', sections)
                    this.formData.sections = [];

                    if (this.formData.price) this.formData.price = this.formData.price.replace('.', ',')
                    if (this.formData.promotional_price) this.formData.promotional_price = this.formData.promotional_price.replace('.', ',')
                    this.show = false;

                    this.copy = JSON.parse(JSON.stringify(this.formData));
                    this.copy.sections = JSON.parse(JSON.stringify(sections));
                    this.copy.tags = JSON.parse(JSON.stringify(this.tags));

                    if (response.data.photo !== '') document.getElementById('logo').required = false;

                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        setTextApplicationForm() {
            if (this.formData.application_form === '1') {
                this.formData.application_form_text = `Preencha o formulário abaixo, para analisarmos o seu perfil e informar sobre o ingresso no nosso Programa de Mentoria ${this.formData.title}.\n
Este é um Formulário de aplicação inicial projetado para que você obtenha o máximo de benefício da nossa mentoria e que possamos atender às suas necessidades específicas.\n
Caso fique com alguma dúvida entre em contato conosco através do e-mail ${this.currentUser.email}.`;
            } else {
                this.formData.application_form_text = ''
            }
        },

        addBenefit() {
            this.formData.benefits.push({
                title: '',
                description: ''
            });
        },

        removeBenefit(index) {
            this.formData.benefits.splice(index, 1);
        },

        addTestimonial() {
            this.formData.testimonials.push({
                name: '',
                text: ''
            });
        },

        removeTestimonial(index) {
            this.formData.testimonials.splice(index, 1);
        },

        handleTestimonialImageChange(event, index) {
            // Implemente a lógica para lidar com a mudança da imagem do depoimento
        },

        addFaq() {
            this.formData.faqs.push({
                question: '',
                answer: ''
            });
        },

        removeFaq(index) {
            this.formData.faqs.splice(index, 1);
        },

        generateBenefits() {
            const content = this.formData.title + this.formData.description;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-benefits', {content})
                .then(response => {
                    this.formData.benefits = response.data.map(benefit => ({
                        title: benefit.title,
                        description: benefit.description
                    }));
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

        generateTestimonials() {
            const content = this.formData.title + this.formData.description;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-testimonials', {content})
                .then(response => {
                    this.formData.testimonials = response.data.map(testimonial => ({
                        name: testimonial.name,
                        text: testimonial.text
                    }));
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

        generateFaqs() {
            const content = this.formData.title + this.formData.description;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-faqs', {content})
                .then(response => {
                    this.formData.faqs = response.data.map(faq => ({
                        question: faq.question,
                        answer: faq.answer
                    }));
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

    },
    mounted() {
        this.$store.commit('mentoring/resetModules');
        const id = this.$route.params.id;
        if (id) this.getData(id);

        this.handleStorageEvent = (event) => {
            if (event.key === 'new_form') {
                this.getForms();
            }
        };

        window.addEventListener('storage', this.handleStorageEvent);
    },
    created() {
        this.getForms();
    },
    beforeDestroy() {
        window.removeEventListener('storage', this.handleStorageEvent);
    },
    computed: {
        modules() {
            return this.$store.state.mentoring.modules;
        },
        currentUser() {
            return this.$store.state.auth.user;
        }
    }
}
</script>

<style scoped>

.tag {
    background-color: #efefef;
    margin: 2px;
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.remove-tag {
    margin-left: auto;
    cursor: pointer;
}

.mentor-imagem {
    width: 100%;
    height: 400px;
}

input[type=color].form-control {
    height: calc(1.5em + 1rem + 2px);
}
</style>
